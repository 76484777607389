let endpoints = {
    verification: '',
    issuer: '',
    rekognition: ''
}

const setEndpoint = (environment: string) => {
    console.log("setting env", environment)
    const productionEndpoint = "https://api.trential.app"
    const developmentEndpoint = "https://api.trential.dev"
    const base = (environment == "development" ? developmentEndpoint : productionEndpoint)
    console.log("base", base)
    endpoints = {
        verification: `${base}/verification/group-txn`,
        issuer: `${base}/trential`,
        rekognition: `${base}/rekognition`
    }
    console.log("after setting env", endpoints)
}

const getEndpoint = () => {
    console.log("gettingendpoints", endpoints)
    return endpoints
}

const routes = {
    passport: '/api/1.0/verifications/documents/passport',
    jpResidentCard: '/api/1.0/verifications/documents/japanese-resident-card',
    uploadDL: '/api/1.0/verifications/documents/dl',
    getAadhaarOtp: '/api/1.0/verifications/otp/aadhaar',
    submitAadhaarOtp: '/api/1.0/verifications/aadhaar',
    submitAadhaarConfirmation: '/api/1.0/verifications/aadhaar/confirm',
    submitDlConfirmation: '/api/1.0/verifications/dl/confirm',
    submitPassportConfirmation: '/api/1.0/verifications/passport/confirm',
    submitJpResidentCardConfirmation: '/api/1.0/verifications/japanese-resident-card/confirm',
    drivingLicense: '/api/1.0/verifications/dl',
    drivingLicenseCaptcha: '/api/1.0/verifications/dl/verification-captcha',
    liveness: {
        sessionToken: "/api/1.0/images/sessionToken",
        sessionId: "/api/1.0/images/liveness",
        submit: '/api/1.0/verifications/liveness',
    },
}

export {
    endpoints, getEndpoint, routes, setEndpoint
}

