// @ts-nocheck
import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './wrapper';

(async () => {
    const service = process.env.REACT_APP_ENV == "production" ? "https://api.trential.app" : "https://api.trential.dev"
    const response = await axios({
      url: `${service}/verification/api/1.0/transactions/start-group-verification`,
      method: "POST",
      headers: {"x-api-key": process.env.REACT_APP_X_API_KEY},
      data: { "verificationNameList": ["aadhaar", "dl", "passport", "liveness", "japanese-resident-card"]}
    })
    if (response?.data?.data) {
      const token = response?.data?.data?.token;
      if (token) {
        localStorage.setItem('tft', token); // token for testing
        console.log("from app");
        // Use ReactDOM.render for older versions of React
        ReactDOM.render(
            <App token={token} 
            enableDlCaptcha={true}
            environment={process.env.REACT_APP_ENV} />,
            document.getElementById('root')
        );
      } else {
        console.log('Token not found for testing');
      }
    }
})();
